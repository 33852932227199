import {
  API_TYPES,
  type GetWorkerRequirementsResponse,
} from "@clipboard-health/contract-worker-app-bff";

import { type WorkerRequirement } from "./types";

/**
 * Merges all worker requirements with their associated requirement details from the API response.
 * @param data - The full API response containing the requirements.
 * @returns An array of objects that combine each worker requirement with its associated requirement details.
 */
export function mergeWorkerRequirementsWithRequirements(
  data: GetWorkerRequirementsResponse
): WorkerRequirement[] {
  const requirementsMap = new Map(
    data.included
      ?.filter((resource) => resource.type === API_TYPES.requirements)
      .map((request) => [request.id, request.attributes])
  );

  return data.data.map((workerRequirement) => {
    const { attributes: workerRequirementAttributes } = workerRequirement;
    const { requirementId } = workerRequirementAttributes;
    const requirement = requirementsMap.get(requirementId);
    return { ...requirement, ...workerRequirementAttributes };
  });
}
