import {
  API_TYPES,
  type GetWorkerRequirementsResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { type UseGetQueryOptions } from "@src/appV2/api";
import { type ModalShiftData } from "@src/appV2/redesign/Shift/types";
import { type AxiosError } from "axios";

import { MAX_PAGE_SIZE_FOR_ALL_WORKER_REQUIREMENTS } from "./constants";
import { mergeWorkerRequirementsWithRequirements } from "./mergeWorkerRequirementAndRequirement";
import { type WorkerRequirement } from "./types";
import { useGetWorkerRequirements } from "./useGetWorkerRequirements";

interface UseGetRequirementsForShiftProps {
  shift?: ModalShiftData;
  qualification?: string;
}

export function useGetRequirementsForShift(
  props: UseGetRequirementsForShiftProps,
  options: UseGetQueryOptions<GetWorkerRequirementsResponse, AxiosError, WorkerRequirement[]> = {}
) {
  const { shift, qualification } = props;
  const { workplace, attributes: { end, qualification: shiftQualification } = {} } = shift ?? {};

  return useGetWorkerRequirements<WorkerRequirement[]>(
    {
      filter: {
        workplaceIds: [workplace?.id ?? ""],
        qualifications: [qualification ?? shiftQualification ?? ""],
        expiresAt: { gte: end ?? "" },
        requiredForBooking: true,
      },
      include: [API_TYPES.requirements],
      page: { size: MAX_PAGE_SIZE_FOR_ALL_WORKER_REQUIREMENTS },
    },
    {
      enabled: isDefined(shift) && (options.enabled ?? true),
      select: mergeWorkerRequirementsWithRequirements,
      ...options,
    }
  );
}
