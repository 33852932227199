import { type WorkerRequirement } from "../api/types";

interface IsRequirementInstantReviewProps {
  workerRequirement?: WorkerRequirement;
}

export function isRequirementInstantReview(props: IsRequirementInstantReviewProps) {
  const { workerRequirement } = props;
  return Boolean(
    workerRequirement?.isAutomatedVerificationDecision &&
      workerRequirement?.helloSignConfig?.isEmbeddedFlowEnabled
  );
}
