import { Text, type TextProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib/Calendar/types";

import { getFormattedTimeRangeWithTimezone } from "../utils/getFormattedTimeRangeWithTimezone";

export interface TimeRangeLabelProps extends TextProps {
  dateRange: DateRange;
  timezone?: string;
  deviceTimezone?: string;
}

/**
 * Renders text with a time range in the format "9:30 am - 2:15 pm"
 * If `timezone` and `deviceTimezone` are passed and different, it will append the abbreviation of the reference timezone
 * Example:
 * - "9:30 am - 2:15 pm"
 * - "9:30 am - 2:15 pm (EST)"
 */
export function TimeRangeLabel(props: TimeRangeLabelProps) {
  const { dateRange, timezone, deviceTimezone, ...textProps } = props;

  const { label, timezoneAbbreviation } = getFormattedTimeRangeWithTimezone({
    dateRange,
    timezone,
    deviceTimezone,
  });

  return (
    <Stack direction="row" alignItems="baseline" spacing={1} flexWrap="wrap">
      <Text variant="body2" {...textProps}>
        {label}
      </Text>

      {isDefined(timezoneAbbreviation) && <Text variant="caption">({timezoneAbbreviation})</Text>}
    </Stack>
  );
}
