import { Badge } from "@clipboard-health/ui-components";
import { Stack } from "@mui/material";

import type { TimeSlot } from "../Shift/Open/types";
import { TimeSlotIcon } from "../Shift/TimeSlotIcon";

interface BookedShiftsIconRowProps {
  bookedShiftTimeSlots: TimeSlot[];
  maxShiftIconsCountToShow: number;
  disabled?: boolean;
}

export function BookedShiftsIconRow(props: BookedShiftsIconRowProps) {
  const { bookedShiftTimeSlots, maxShiftIconsCountToShow, disabled } = props;

  if (bookedShiftTimeSlots.length === 0) {
    return null;
  }

  if (bookedShiftTimeSlots.length > maxShiftIconsCountToShow) {
    return (
      <Badge
        color="success"
        count={bookedShiftTimeSlots.length}
        size="small"
        sx={{
          "& .MuiBadge-badge": {
            position: "static",
            transform: "none",
          },
        }}
      />
    );
  }

  return (
    <Stack
      direction="row"
      spacing="0.125rem"
      sx={(theme) => ({
        color: disabled ? theme.palette.intent?.disabled.text : theme.palette.success.main,
      })}
    >
      {bookedShiftTimeSlots.map((timeSlot) => (
        <TimeSlotIcon key={timeSlot} timeSlot={timeSlot} size="small" />
      ))}
    </Stack>
  );
}
