import {
  type GetWorkerRequirementRequest,
  type GetWorkerRequirementsResponse,
  getWorkerRequirementsResponseSchema,
} from "@clipboard-health/contract-worker-app-bff";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { type AxiosError } from "axios";
import { minutesToMilliseconds } from "date-fns";

export const GET_WORKER_REQUIREMENTS_PATH = "/worker-requirements";
export const GET_WORKER_REQUIREMENTS_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}${GET_WORKER_REQUIREMENTS_PATH}`;

export function useGetWorkerRequirements<TransformedData = GetWorkerRequirementsResponse>(
  queryParams: GetWorkerRequirementRequest,
  options: UseGetQueryOptions<GetWorkerRequirementsResponse, AxiosError, TransformedData> = {}
) {
  return useGetQuery<GetWorkerRequirementRequest, GetWorkerRequirementsResponse, TransformedData>({
    url: GET_WORKER_REQUIREMENTS_URL,
    queryParams,
    responseSchema: getWorkerRequirementsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_REQUIREMENTS_FAILURE,
      userErrorMessage: "Something went wrong while loading worker requirements",
    },
    staleTime: options.staleTime ?? minutesToMilliseconds(5),
    ...options,
  });
}
