import { formatDate } from "@clipboard-health/date-time";
import { Pill, type PillSize } from "@clipboard-health/ui-components";
import { type IntentVariant } from "@clipboard-health/ui-theme";
import { isNil } from "@clipboard-health/util-ts";
import { differenceInDays } from "date-fns";

export const DOCUMENT_STATUS_PILL_VARIANTS = [
  "expiring_soon",
  "rejected",
  "expired",
  "approved",
  "in_review",
  "valid_until",
] as const;

export type DocumentStatusPillVariant = (typeof DOCUMENT_STATUS_PILL_VARIANTS)[number];

export interface DocumentStatusPillProps {
  variant: DocumentStatusPillVariant;
  expiry?: Date;
  size?: PillSize;
}

const STATUS_LABELS: Record<DocumentStatusPillVariant, string> = {
  expiring_soon: "Expiring in",
  rejected: "Rejected",
  expired: "Expired",
  approved: "Approved",
  in_review: "In review",
  valid_until: "Valid until",
};

const STATUS_COLORS: Record<DocumentStatusPillVariant, IntentVariant> = {
  expiring_soon: "warning",
  rejected: "error",
  expired: "error",
  approved: "success",
  in_review: "neutral",
  valid_until: "disabled",
};

export function DocumentStatusPill(props: DocumentStatusPillProps) {
  const { variant, expiry, size = "medium" } = props;

  let label = STATUS_LABELS[variant];

  if (variant === "expiring_soon" || variant === "valid_until") {
    if (isNil(expiry)) {
      return null;
    }

    if (variant === "expiring_soon") {
      const daysUntilExpiry = differenceInDays(expiry, new Date());
      if (daysUntilExpiry < 0) {
        return null;
      }

      label = daysUntilExpiry === 0 ? "Expires today" : `Expires in ${daysUntilExpiry}d`;
    } else {
      label = `${label} ${formatDate(expiry)}`;
    }
  }

  return (
    <Pill
      size={size}
      label={label}
      backgroundColor={(theme) => theme.palette.intent?.[STATUS_COLORS[variant]]?.background}
      color={(theme) => theme.palette.intent?.[STATUS_COLORS[variant]]?.text}
      iconColor={(theme) => theme.palette.intent?.[STATUS_COLORS[variant]]?.icon}
    />
  );
}
