import { WorkerRequirementStatus } from "@clipboard-health/contract-worker-app-bff";
import { isNil } from "@clipboard-health/util-ts";

import { type WorkerRequirement } from "../api/types";

export function getBlockingWorkerRequirements(
  workerRequirements?: WorkerRequirement[]
): WorkerRequirement[] {
  if (isNil(workerRequirements)) {
    return [];
  }

  return workerRequirements.filter((requirement) => {
    // requirement could be valid but of invalid status if there are multiple submitted documents
    // ie: latest doc is rejected, but there is still a valid doc that was submitted before
    if (requirement.isValid) {
      return false;
    }

    // Only consider non-approved requirements.
    return requirement.status !== WorkerRequirementStatus.APPROVED;
  });
}

export function hasBlockingWorkerRequirements(workerRequirements?: WorkerRequirement[]): boolean {
  if (isNil(workerRequirements)) {
    return false;
  }

  return getBlockingWorkerRequirements(workerRequirements).length > 0;
}

export function countBlockingWorkerRequirements(workerRequirements?: WorkerRequirement[]): number {
  if (isNil(workerRequirements)) {
    return 0;
  }

  return getBlockingWorkerRequirements(workerRequirements).length;
}
