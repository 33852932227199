import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { LoadingSpinner } from "@clipboard-health/ui-components";
import { mergeSxProps, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase, type SxProps, type Theme } from "@mui/material";
import { getDate } from "date-fns";
import { type ForwardedRef, forwardRef } from "react";

export interface CalendarCellProps {
  date: Date;
  isSelected?: boolean;
  isLoading?: boolean;
  isToday?: boolean;
  onToggleSelect?: (isSelected: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

function BaseCalendarCell(props: CalendarCellProps, ref: ForwardedRef<HTMLButtonElement>) {
  const {
    date,
    isSelected,
    onToggleSelect,
    disabled = false,
    children,
    isLoading = false,
    isToday = false,
    sx,
  } = props;

  const shortDate = formatShortDateWithWeekday(date);
  const dayOfMonth = getDate(date);

  return (
    <ButtonBase
      ref={ref}
      aria-selected={isSelected}
      aria-label={shortDate}
      disabled={disabled || isLoading}
      sx={mergeSxProps(
        (theme) => ({
          alignItems: "center",
          borderRadius: theme.borderRadius?.xSmall,
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          boxSizing: "border-box",
          borderWidth: "2px",
          borderColor: "transparent",
          borderStyle: "solid",
          backgroundColor: theme.palette.background.tertiary,
          width: theme.size?.calendarCell.width,
          height: theme.size?.calendarCell.height,
          justifyContent: "flex-start",
          position: "relative",
          padding: 1,
          gap: 1,
          transition: `${theme.transitions.create(["border-color", "background-color"], {
            duration: theme.transitions.duration.standard,
          })}`,
          userSelect: "none",

          // only implement hover on supported devices
          "@media (hover: hover)": {
            "&:not(:disabled):hover": {
              borderColor: theme.palette.border?.subtleInvert,
            },
          },

          "&:not(:disabled)[aria-selected=true]": {
            borderColor: theme.palette.border?.subtleInvert,
          },

          "&:disabled": {
            color: theme.palette.intent?.disabled.text,

            "& .MuiTypography-root": {
              color: theme.palette.intent?.disabled.text,
            },
          },
        }),
        sx
      )}
      onClick={(event) => {
        if (!disabled && isDefined(onToggleSelect)) {
          onToggleSelect(!isSelected, event);
        }
      }}
    >
      {isLoading ? (
        <Box margin="auto">
          <LoadingSpinner size="small" />
        </Box>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "2rem", // 32px
              height: "2rem", // 32px
              borderRadius: "50%",
              backgroundColor: isToday ? theme.palette.background.invert : "transparent",
            })}
          >
            <Text
              variant="h6"
              sx={(theme) => ({
                color: isToday ? theme.palette.text.invertPrimary : theme.palette.text.primary,
                lineHeight: "1rem",
              })}
            >
              {dayOfMonth}
            </Text>
          </Box>

          {children}
        </>
      )}
    </ButtonBase>
  );
}

export const CalendarCell = forwardRef(BaseCalendarCell);
